import React from "react"

import Layout from "../../components/layout"
import Hero from "../../components/hero"
import PageContent from "../../page-views/about/company-profile.js"
import BgImage from "../../assets/images/2017/08/mining-chemical-plant-slide.jpg"

const CompanyProfilePage = () => {
  const title = "Company Profile"
  const content = "A world leader for 25 years and counting"

  return (
    <Layout>
      <Hero title={title} content={content} bgImage={BgImage} />
      <PageContent />
    </Layout>
  )
}

export default CompanyProfilePage

import React from "react"

const PageContent = () => {
  return (
    <div className="container">
      <div className="page-content">
        <p>
          Inductabend specialises in high integrity heat induction bending of
          pipe and other sections. Inductabend operates a range of specialised
          induction machines including two automated Cojafex
        </p>
        <p>
          PB 850 SE induction bending machines. Our stated aim is to offer
          bending service excellence to industry.
        </p>

        <h2>
          <strong>Induction Bending Capacity</strong>
        </h2>
        <p>Cojafex PB 850 SE:</p>
        <ul>
          <li>50 mm to 900 mm diameter pipe</li>
          <li>wall thickness up to 100 mm</li>
          <li>other heavy sections</li>
        </ul>
        <p>The available bending radii varies from:</p>
        <ul>
          <li>100 mm to 12,000 mm depending on section size.</li>
          <li>Larger radius bends are available by special arrangement.</li>
        </ul>

        <h2>
          <strong>Cold Bending Capacity</strong>
        </h2>
        <p>
          We also offer a wide range of cold bending and rolling of pipe and
          structural sections through our sister company Smithweld Pty. Ltd.
        </p>

        <h2>
          <strong>Technology</strong>
        </h2>
        <p>
          Inductabend is committed to the development and application of leading
          induction bending technologies.
        </p>
        <p>
          Cojafex Induction Bending is a hot bending process specifically
          designed to produce high quality pipe bends – although other sections
          may also be bent by this process; including RHS and heavy structural
          sections.
        </p>
        <p>
          Cojafex induction bending machines are recognised and acknowledged
          internationally as a benchmark for the induction bending process.
          Inductabend uses Cojafex software and historical database records to
          predict machine bending parameters and to ensure compliance to bend
          specifications. For process control and Quality Assurance, the Cojafex
          is automated with programmable numerically controls. An integrated
          dual wavelength fibre-optic pyrometer system continuously records and
          plots the process thermal history for up to four separate probes.
        </p>

        <h2>Materials</h2>
        <p>
          Materials which can be successfully induction bent include all alloy
          steels, low and high carbon steels, high X grade linepipe steels,
          chrome-nickel alloys and titanium.
        </p>

        <h2>Clients</h2>
        <p>Industries serviced by this technology include:</p>
        <ul>
          <li>Oil and gas</li>
          <li>Power generation</li>
          <li>Petrochemical</li>
          <li>Mining and mineral processing</li>
          <li>Chemical and food processing</li>
          <li>Construction and Ship building</li>
        </ul>
      </div>
    </div>
  )
}

export default PageContent
